import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class MaterialsProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MER_API)
  }

  getAll (query) {
    this.setHeader(getAuthToken())
    return this.get('/materials', query)
  }

  getById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/materials/${id}`)
  }

  getHistoryById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/materials/histories/${id}`)
  }

  getNameExist (id, name) {
    this.setHeader(getAuthToken())
    return this.get(`/materials/is-exist?id=${id}&name=${name}`)
  }

  createOne (form) {
    this.setHeader(getAuthToken())
    return this.post('/materials', form)
  }

  updateOne (id, form) {
    this.setHeader(getAuthToken())
    return this.put(`/materials/${id}`, form)
  }

  deleteOne (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/materials/${id}`)
  }
}

export default MaterialsProvider
